import EmployeesRangeIcon from "@material-ui/icons/GroupWork";

import EmployeesRangeCreate from "./EmployeesRangeCreate";
import EmployeesRangeEdit from "./EmployeesRangeEdit";
import EmployeesRangeList from "./EmployeesRangeList";
import EmployeesRangeShow from "./EmployeesRangeShow";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: EmployeesRangeList,
  show: EmployeesRangeShow,
  edit: EmployeesRangeEdit,
  create: EmployeesRangeCreate,
  icon: EmployeesRangeIcon,
};
