import SocialGroupIssueIcon from "@material-ui/icons/BugReport";

import SocialGroupIssueCreate from "./SocialGroupIssueCreate";
import SocialGroupIssueEdit from "./SocialGroupIssueEdit";
import SocialGroupIssueList from "./SocialGroupIssueList";
import SocialGroupIssueShow from "./SocialGroupIssueShow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SocialGroupIssueList,
  show: SocialGroupIssueShow,
  edit: SocialGroupIssueEdit,
  create: SocialGroupIssueCreate,
  icon: SocialGroupIssueIcon,
};
