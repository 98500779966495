import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, SearchInput } from "react-admin";

const SocialGroupIssueFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Name" source="name" alwaysOn resettable />
  </Filter>
);
const SocialGroupIssueList = (props: any) => {
  return (
    <ListGuesser filters={<SocialGroupIssueFilter />} rowClick="edit" {...props} exporter={false}>
      <FieldGuesser source="name" />
    </ListGuesser>
  );
};

export default SocialGroupIssueList;
