import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";

import { EmployeesRange } from "../interfaces/employeesrange";

const EmployeesRangeList = (props: EmployeesRange) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser source="name" />
    <FieldGuesser source="position" />
  </ListGuesser>
);

export default EmployeesRangeList;
