const roles = [
  { id: "ADMIN_LEVEL1", name: "No Analytics" },
  { id: "ADMIN_LEVEL2", name: "Workforce Analytics" },
  { id: "ADMIN_LEVEL3", name: "Front-End Analytics" },
  { id: "ADMIN_LEVEL4", name: "Full Looker Analytics" },
  { id: "ADMIN_LEVEL5", name: "Completion" },
  { id: "ADMIN_LEVEL6", name: "Completion and Question" },
  { id: "ADMIN_LEGAL", name: "Legal OSA" },
];

const enables = [
  { id: 0, name: "In-Active" },
  { id: 1, name: "Active" },
];

const userTypes = [
  { id: "genuine", name: "Genuine" },
  { id: "generic", name: "Generic" },
];

const transgenderIdentifications = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
  { id: "prefer-not-to-say", name: "Prefer Not To Say" },
];

const differentlyAbleds = [
  { id: "apparent", name: "Apparent" },
  { id: "non-apparent", name: "Non Apparent" },
  { id: "none", name: "None" },
  { id: "self-describe", name: "Self Describe" },
];

const employeeStatuses = [
  { id: "full-time", name: "Full Time" },
  { id: "part-time", name: "Part Time" },
  { id: "Contractor", name: "Contractor" },
];

const nativeLanguages = [
  { id: "english", name: "English" },
  { id: "no-english", name: "No English" },
];

const familyStatuses = [
  { id: "no-children", name: "No Children" },
  { id: "partnered-parent", name: "Partnered Parent" },
  { id: "single-parent", name: "Single Parent" },
];

const oauthProviders = [
  { id: "facebook", name: "FaceBook" },
  { id: "linkedin", name: "LinkedIn" },
];

const privacies = [
  { id: "anonymous", name: "Anonymous" },
  { id: "full-name", name: "Full Name" },
  { id: "first-name", name: "First Name" },
];

export { roles, enables, userTypes, transgenderIdentifications, differentlyAbleds, employeeStatuses, nativeLanguages, familyStatuses, oauthProviders, privacies };
