import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, ReferenceInput, AutocompleteInput, Filter } from "react-admin";
const SurveyCompanySurveyFormQuestionFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput filterToQuery={(searchText) => ({ title: searchText })} label="Survey Company" reference="survey_companies" source="surveyCompany">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput filterToQuery={(searchText) => ({ label: searchText })} label="Survey Question" reference="survey_form_questions" source="surveyFormQuestion">
      <AutocompleteInput optionText="label" optionValue="@id" />
    </ReferenceInput>
  </Filter>
);

const SurveyCompanySurveyFormQuestionList = (props: any) => (
  <ListGuesser filters={<SurveyCompanySurveyFormQuestionFilters />} {...props} exporter={false}>
    <ReferenceField label="Question" reference="survey_form_questions" source="surveyFormQuestion">
      <TextField source="label" />
    </ReferenceField>

    <ReferenceField label="Parent Question" reference="survey_form_questions" source="parentSurveyFormQuestion">
      <TextField source="label" />
    </ReferenceField>
    <FieldGuesser source="rank" />
    <FieldGuesser source="info" />
    <FieldGuesser source="level" />
    <FieldGuesser source="isRequired" />
    <FieldGuesser source="maxAnswerSelection" />
    <FieldGuesser source="typeRestriction" />
    <FieldGuesser source="isVisible" />
  </ListGuesser>
);

export default SurveyCompanySurveyFormQuestionList;
