import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField } from "react-admin";

import { SurveyFormStepSurveyFormQuestion } from "../interfaces/surveyformstepsurveyformquestion";

const SurveyFormStepSurveyFormQuestionList = (props: SurveyFormStepSurveyFormQuestion) => (
  <ListGuesser {...props} exporter={false}>
    <ReferenceField label="Survey Category" reference="survey_form_steps" source="surveyFormStep">
      <TextField source="title" />
    </ReferenceField>
    <ReferenceField label="Survey Company" reference="survey_companies" source="surveyCompany">
      <TextField source="title" />
    </ReferenceField>
    <FieldGuesser source="rank" />
    <FieldGuesser source="info" />
  </ListGuesser>
);

export default SurveyFormStepSurveyFormQuestionList;
