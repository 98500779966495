import * as React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { TextField, ReferenceField } from "react-admin";

const AdminShow = (props: any) => (
  <ShowGuesser {...props}>
    <ReferenceField label="User" reference="users" source="user" fullWidth>
      <TextField source="fullNameWithEmail" />
    </ReferenceField>
    <ReferenceField label="Company" reference="companies" source="company" fullWidth>
      <TextField source="name" />
    </ReferenceField>

    <FieldGuesser source="createdAt" addLabel />
  </ShowGuesser>
);

export default AdminShow;
