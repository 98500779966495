import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";

const AnnualReportList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source="year" />
    <FieldGuesser source="company" />
    <FieldGuesser source="metrics" />
    <FieldGuesser source="totals" />
    <FieldGuesser source="type" />
    <FieldGuesser source="orderedMetrics" />
    <FieldGuesser source="createdAt" />
    <FieldGuesser source="updatedAt" />
  </ListGuesser>
);

export default AnnualReportList;
