import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";

import { SocialGroupIssue } from "../interfaces/socialgroupissue";

const SocialGroupIssueTitle = (props: any) => {
  return <span>Social Group Issue {props.record ? `"${props.record.name}"` : ""}</span>;
};

const SocialGroupIssueEdit = (props: SocialGroupIssue) => (
  <EditGuesser title={<SocialGroupIssueTitle />} {...props}>
    <InputGuesser source="name" />
  </EditGuesser>
);

export default SocialGroupIssueEdit;
