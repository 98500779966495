import SurveyCompanyIcon from "@material-ui/icons/ReceiptTwoTone";

import SurveyFormCreate from "./SurveyFormCreate";
import SurveyFormEdit from "./SurveyFormEdit";
import SurveyFormList from "./SurveyFormList";
import SurveyFormShow from "./SurveyFormShow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyFormList,
  show: SurveyFormShow,
  edit: SurveyFormEdit,
  create: SurveyFormCreate,
  icon: SurveyCompanyIcon,
};
