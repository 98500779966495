const questionTypes: Array<any> = [
  { id: "text", name: "Text" },
  { id: "textarea", name: "Open Ended Answer" },
  { id: "choice-true-false", name: "True/False" },
  { id: "file-upload", name: "File Upload" },
  { id: "choice-unique-collapsed", name: "Select Dropdown" },
  { id: "choice-unique-expanded", name: "Radio Selection" },
  { id: "choice-multiple-collapsed", name: "Select multiple" },
  { id: "choice-multiple-expanded", name: "Checkboxes" },
  { id: "multi-line-open-ended", name: "Open Ended Answer w/ Multiple Lines" },
  { id: "slider", name: "Slider" },
  { id: "ranking", name: "Rank" },
  { id: "date-time", name: "Date / Time" },
  { id: "matrix", name: "Matrix" },
];

const fileTypes: Array<any> = [
  { id: "application/pdf#application/x-pdf", name: "PDF" },
  { id: "application/msword#application/vnd.openxmlformats-officedocument.wordprocessingml.document", name: "DOC, DOCX" },
  { id: "image/png", name: "PNG" },
  { id: "image/jpg#image/jpeg", name: "JPG, JPEG" },
  { id: "image/gif", name: "GIF" },
];

const profileFieldNames: Array<any> = [
  { id: "Family Status", name: "Family Status" },
  { id: "Native Language", name: "Native Language" },
  { id: "Gender", name: "Gender" },
  { id: "First Generation Status", name: "First Generation Status" },
  { id: "Care Giver Status", name: "Care Giver Status" },
  { id: "Education Level", name: "Education Level" },
  { id: "Household Income", name: "Household Income" },
  { id: "Veteran Status", name: "Veteran Status" },
  { id: "Politics", name: "Politics" },
  { id: "Office Status", name: "Office Status" },
  { id: "Marital Status", name: "Marital Status" },
  { id: "Family Status", name: "Family Status" },
  { id: "Native Language", name: "Native Language" },
  { id: "Transgender", name: "Transgender" },
  { id: "Race/ Ethnicity", name: "Race/ Ethnicity" },
  { id: "Ethnicity", name: "Ethnicity" },
  { id: "Religion", name: "Religion" },
  { id: "Disability", name: "Disability" },
  { id: "Sexuality", name: "Sexuality" },
  { id: "Gender Other", name: "Gender Other" },
  { id: "Race Other", name: "Race Other" },
  { id: "Ethnicity Other", name: "Ethnicity Other" },
  { id: "Sexuality Other", name: "Sexuality Other" },
  { id: "Religion Other", name: "Religion Other" },
  { id: "Disability Other", name: "Disability Other" },
];

const demographicsTypes = [
  { id: "general", name: "General" },
  { id: "genders", name: "Genders" },
  { id: "races", name: "Races" },
  { id: "sexual_orientations", name: "Sexual Orientations" },
  { id: "religions", name: "Religions" },
];

const subTypes = [
  { id: "yesno", name: "Yes/No" },
  { id: "likert", name: "Likert" },
  { id: "highestNotatall", name: "Highest/Not at all" },
  { id: "frequency", name: "Frequency" },
];

const dateFormats = [
  { id: "mm/dd/yy", name: "MM/DD/YYYY" },
  { id: "dd/mm/yy", name: "DD/MM/YYYY" },
];

const dateTimes = [
  { id: "date", name: "Date" },
  { id: "time", name: "Time" },
];

const trueFalse = [
  { value: "True", position: 0 },
  { value: "False", position: 1 },
];

const blankAnswerRows = [
  { value: "", position: 0 },
  { value: "", position: 1 },
];

const formAnswersYesNo = [
  {
    value: "Yes",
    position: 0,
  },
  {
    value: "No",
    position: 1,
  },
  {
    value: "Not Sure",
    position: 2,
  },
  {
    value: "Not Applicable",
    position: 3,
  },
];
const formAnswersLikert = [
  {
    value: "Strongly Agree",
    position: 0,
  },
  {
    value: "Agree",
    position: 1,
  },
  {
    value: "Neutral",
    position: 2,
  },
  {
    value: "Disagree",
    position: 3,
  },
  {
    value: "Strongly Disagree",
    position: 4,
  },
  {
    value: "Not Applicable",
    position: 5,
  },
];
const formAnswersHighestNotatall = [
  {
    value: "Highest",
    position: 0,
  },
  {
    value: "High",
    position: 1,
  },
  {
    value: "Medium",
    position: 2,
  },
  {
    value: "Low",
    position: 3,
  },
  {
    value: "Not at All",
    position: 4,
  },
];
const formAnswersFrequency = [
  {
    value: "Constantly",
    position: 0,
  },
  {
    value: "Frequently",
    position: 1,
  },
  {
    value: "Sometimes",
    position: 2,
  },
  {
    value: "Rarely",
    position: 3,
  },
  {
    id: 5,
    value: "Never",
    position: 4,
  },
  {
    value: "Not Applicable",
    position: 5,
  },
];

export { questionTypes, fileTypes, profileFieldNames, demographicsTypes, subTypes, dateFormats, dateTimes, formAnswersYesNo, formAnswersFrequency, formAnswersLikert, formAnswersHighestNotatall, trueFalse, blankAnswerRows };
