import React from "react";
import { ResourceGuesser, HydraAdmin, hydraSchemaAnalyzer } from "@api-platform/admin";

import dataProvider from "./utils/dataProvider";
import authProvider from "./utils/authProvider";
import { Login } from "./layout";
import Layout from "./layout/Layout";
import articles from "./articles";
import surveyFormQuestions from "./survey_form_questions";
import pinpointCampaign from "./pinpoint_campaigns";
import categories from "./categories";
import socialGroupIssues from "./social_group_issues";
import industries from "./industries";
import employeesRanges from "./employees_ranges";
import companies from "./companies";
import benefits from "./benefits";
import annualReports from "./annual_reports";
import users from "./users";
import userFollowCompanies from "./user_follow_companies";
import surveyCompanies from "./survey_companies";
import deploymentTypes from "./deployment_types";
import surveyFormStepSurveyFormQuestions from "./survey_form_step_survey_form_questions";
import surveyFormSteps from "./survey_form_steps";
import surveyForms from "./survey_forms";
import surveyCompanyTokens from "./survey_company_tokens";
import tokens from "./tokens";
import surveyCompanySurveyFormQuestions from "./survey_company_survey_form_questions";
import Dashboard from "./Dashboard";
import pinpointCampaignUsers from "./pinpoint_campaign_users";
import NotFound from "./NotFound";
import i18nProvider from "./i18n/i18nProvider";
import mediaImages from "./media_images";
import admin from "./admin";
import locales from "./locales";
import pages from "./pages";
import survey_contributions from "./survey_contributions";
import userActivityLogs from "./user_activity_logs";
import campaignSchedules from "./campaign_schedules";

const AdminLoader = () => {
  if (typeof window !== "undefined") {
    return (
      <HydraAdmin authProvider={authProvider} catchAll={NotFound} dashboard={Dashboard} dataProvider={dataProvider} entrypoint={window.origin} i18nProvider={i18nProvider} layout={Layout} loginPage={Login} schemaAnalyzer={hydraSchemaAnalyzer()}>
        <ResourceGuesser name="articles" {...articles} />
        <ResourceGuesser name="survey_form_questions" {...surveyFormQuestions} options={{ label: "Survey Questions/Answers" }} />
        <ResourceGuesser name="pinpoint_campaigns" {...pinpointCampaign} />
        <ResourceGuesser name="pinpoint_campaign_users" {...pinpointCampaignUsers} />
        <ResourceGuesser name="categories" {...categories} options={{ label: "Question Categories" }} />
        <ResourceGuesser name="annual_reports" {...annualReports} />
        <ResourceGuesser name="benefits" {...benefits} />
        <ResourceGuesser name="companies" {...companies} />
        <ResourceGuesser name="employees_ranges" {...employeesRanges} />
        <ResourceGuesser name="industries" {...industries} />
        <ResourceGuesser name="social_group_issues" {...socialGroupIssues} />
        <ResourceGuesser name="users" {...users} />
        <ResourceGuesser name="work_experiences" />
        <ResourceGuesser name="badges" />
        <ResourceGuesser name="genders" />
        <ResourceGuesser name="races" />
        <ResourceGuesser name="religions" />
        <ResourceGuesser name="sexual_orientations" />
        <ResourceGuesser name="user_companies" />
        <ResourceGuesser name="education_levels" />
        <ResourceGuesser name="user_follow_companies" {...userFollowCompanies} />
        <ResourceGuesser name="survey_companies" {...surveyCompanies} />
        <ResourceGuesser name="deployment_types" {...deploymentTypes} />
        <ResourceGuesser name="survey_form_step_survey_form_questions" {...surveyFormStepSurveyFormQuestions} />
        <ResourceGuesser name="survey_form_steps" {...surveyFormSteps} options={{ label: "Survey Step Question" }} />
        <ResourceGuesser name="survey_forms" {...surveyForms} options={{ label: "Survey Categories" }} />
        <ResourceGuesser name="survey_company_tokens" {...surveyCompanyTokens} />
        <ResourceGuesser name="tokens" {...tokens} />
        <ResourceGuesser name="survey_company_survey_form_questions" {...surveyCompanySurveyFormQuestions} />
        <ResourceGuesser name="departments" />
        <ResourceGuesser name="positions" />
        <ResourceGuesser name="cities" />
        <ResourceGuesser name="regions" />
        <ResourceGuesser name="pyramid_heads" />
        <ResourceGuesser name="division_heads" />
        <ResourceGuesser name="divisions" />
        <ResourceGuesser name="domains" />
        <ResourceGuesser name="company_information_pages" />
        <ResourceGuesser name="company_information_groups" />
        <ResourceGuesser name="company_information_questions" />
        <ResourceGuesser name="pinpoint_campaign_questions" />
        <ResourceGuesser name="survey_contributions" {...survey_contributions} />
        <ResourceGuesser name="emails" />
        <ResourceGuesser name="survey_form_survey_companies" />
        <ResourceGuesser name="admins" {...admin} />
        <ResourceGuesser name="media_images" {...mediaImages} />
        <ResourceGuesser name="locales" {...locales} />
        <ResourceGuesser name="pages" {...pages} />
        <ResourceGuesser name="native_language_statuses" />
        <ResourceGuesser name="transgender_statuses" />
        <ResourceGuesser name="disability_statuses" />
        <ResourceGuesser name="family_statuses" />
        <ResourceGuesser name="user_activity_logs" {...userActivityLogs} />
        <ResourceGuesser name="campaign_schedules" {...campaignSchedules} />
        <ResourceGuesser name="schedule_recipients" />
      </HydraAdmin>
    );
  }

  return <></>;
};

const App = () => <AdminLoader />;
export default App;
