import UserIcon from "@material-ui/icons/People";

import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import UserShow from "./UserShow";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  icon: UserIcon,
};
