import * as React from "react";
import { SelectInput, InputProps } from "react-admin";

import { differentlyAbleds, employeeStatuses, enables, familyStatuses, nativeLanguages, oauthProviders, roles, transgenderIdentifications, userTypes, privacies } from "./user-dropdown-data";

type Props = {
  source?: string;
} & Omit<InputProps, "source">;

const UserTypeInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={userTypes.map((userType) => ({
        id: userType.id,
        name: userType.name,
      }))}
      source="userType"
      {...rest}
    />
  );
};
UserTypeInput.defaultProps = {
  addField: true,
  source: "userType",
  resource: "users",
};

const RoleInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={roles.map((role) => ({
        id: role.id,
        name: role.name,
      }))}
      source="role"
      {...rest}
    />
  );
};
RoleInput.defaultProps = {
  addField: true,
  source: "role",
  resource: "users",
};

const EnableInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={enables.map((enable) => ({
        id: enable.id,
        name: enable.name,
      }))}
      source="enable"
      {...rest}
    />
  );
};
EnableInput.defaultProps = {
  addField: true,
  source: "enable",
  resource: "users",
};

const OauthProviderInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={oauthProviders.map((oauthProvider) => ({
        id: oauthProvider.id,
        name: oauthProvider.name,
      }))}
      source="oauthProvider"
      {...rest}
    />
  );
};
OauthProviderInput.defaultProps = {
  addField: true,
  source: "oauthProvider",
  resource: "users",
};

const DifferentlyAbledInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={differentlyAbleds.map((differentlyAbled) => ({
        id: differentlyAbled.id,
        name: differentlyAbled.name,
      }))}
      source="differentlyAbled"
      {...rest}
    />
  );
};
DifferentlyAbledInput.defaultProps = {
  addField: true,
  source: "differentlyAbled",
  resource: "users",
};

const TransgenderIdentificationInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={transgenderIdentifications.map((transgenderIdentification) => ({
        id: transgenderIdentification.id,
        name: transgenderIdentification.name,
      }))}
      source="transgenderIdentification"
      {...rest}
    />
  );
};
TransgenderIdentificationInput.defaultProps = {
  addField: true,
  source: "transgenderIdentification",
  resource: "users",
};

const EmployeeStatusInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={employeeStatuses.map((employeeStatus) => ({
        id: employeeStatus.id,
        name: employeeStatus.name,
      }))}
      source="employeeStatus"
      {...rest}
    />
  );
};
EmployeeStatusInput.defaultProps = {
  addField: true,
  source: "employeeStatus",
  resource: "users",
};

const NativeLanguageInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={nativeLanguages.map((nativeLanguage) => ({
        id: nativeLanguage.id,
        name: nativeLanguage.name,
      }))}
      source="nativeLanguage"
      {...rest}
    />
  );
};

NativeLanguageInput.defaultProps = {
  addField: true,
  source: "nativeLanguage",
  resource: "users",
};

const FamilyStatusInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={familyStatuses.map((familyStatus) => ({
        id: familyStatus.id,
        name: familyStatus.name,
      }))}
      source="familyStatus"
      {...rest}
    />
  );
};

FamilyStatusInput.defaultProps = {
  addField: true,
  source: "familyStatus",
  resource: "users",
};

const PrivacyInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={privacies.map((privacy) => ({
        id: privacy.id,
        name: privacy.name,
      }))}
      source="privacy"
      {...rest}
    />
  );
};

PrivacyInput.defaultProps = {
  addField: true,
  source: "privacy",
  resource: "users",
};

export { UserTypeInput, RoleInput, EnableInput, DifferentlyAbledInput, TransgenderIdentificationInput, OauthProviderInput, EmployeeStatusInput, NativeLanguageInput, FamilyStatusInput, PrivacyInput };
