import * as React from "react";
import { ShowGuesser } from "@api-platform/admin";
import { ImageField, ReferenceArrayField, SingleFieldList, ChipField, RichTextField, useRecordContext, SimpleShowLayout, TextField } from "react-admin";

import { MediaImage } from "../interfaces/mediaimage";

const MediaImageTitle = (props: any) => {
  return <span>Media Image {props.record ? `"${props.record.imageAlt}"` : ""}</span>;
};

const MediaImageShowLayout = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout>
      <TextField label="Image Alt Text" source="imageAlt" addLabel />
      <ImageField label="Image" source="imageUrl" />
      <RichTextField label="Login Top Text" source="loginTextTop" addLabel />
      <RichTextField label="Login Bottom Text" source="loginTextBottom" addLabel />
      {record.pinpointCampaigns.length > 0 && (
        <ReferenceArrayField label="Pinpoint Campaigns" reference="pinpoint_campaigns" source="pinpointCampaigns">
          <SingleFieldList linkType={false}>
            <ChipField source="campaignName" />
          </SingleFieldList>
        </ReferenceArrayField>
      )}
    </SimpleShowLayout>
  );
};

const MediaImageShow = (props: MediaImage) => (
  <ShowGuesser title={<MediaImageTitle />} {...props}>
    <MediaImageShowLayout />
  </ShowGuesser>
);

export default MediaImageShow;
