import ArticleIcon from "@material-ui/icons/Announcement";

import ArticleList from "./ArticleList";
import ArticleEdit from "./ArticleEdit";
import ArticleCreate from "./ArticleCreate";
import ArticleShow from "./ArticleShow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ArticleList,
  create: ArticleCreate,
  edit: ArticleEdit,
  show: ArticleShow,
  icon: ArticleIcon,
};
