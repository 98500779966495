import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { useRecordContext, ReferenceField, TextField, Filter, ReferenceInput, AutocompleteInput, SelectInput, SearchInput, BooleanInput } from "react-admin";

import { SurveyFormQuestion } from "../interfaces/surveyformquestion";
import ColoredBooleanFields from "../common/ColoredBooleanFields";

import { QuestionTypeField } from "./SurveyFormQuestionField";
import { questionTypes } from "./dropdowndata";

const SurveyFormQuestionFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Question" source="label" alwaysOn resettable />
    <SelectInput
      choices={questionTypes.map((questionType) => ({
        id: questionType.id,
        name: questionType.name,
      }))}
      label="Question Type"
      optionText="name"
      optionValue="id"
      source="type"
    />
    <ReferenceInput filterToQuery={(searchText) => ({ title: searchText })} label="Question Category" reference="categories" source="category">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
    <BooleanInput label="Is Update Profile?" source="isUpdateProfile" />
  </Filter>
);

const SurveyCategoryCount = (props: any) => {
  const surveyFormQuestion = useRecordContext(props);

  return <span>{surveyFormQuestion.formSteps.length}</span>;
};

SurveyCategoryCount.defaultProps = { label: "Survey Categories" };

const SurveyFormQuestionList = (props: SurveyFormQuestion) => (
  <ListGuesser filters={<SurveyFormQuestionFilters />} {...props} exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
    <FieldGuesser label="Question" source="label" />
    <ColoredBooleanFields label="Rating" source="ratingActivated" />
    <ColoredBooleanFields label="Active?" source="isActive" />
    <QuestionTypeField label="Question Type" source="type" />
    <FieldGuesser label="Type" source="demographicsType" />
    <SurveyCategoryCount />
    <ReferenceField label="Question Category" reference="categories" sortBy="category.title" source="category">
      <TextField source="title" />
    </ReferenceField>
  </ListGuesser>
);

export default SurveyFormQuestionList;
