import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, Filter, ReferenceInput, AutocompleteInput, SearchInput, DateField } from "react-admin";

import { PinpointCampaign } from "../interfaces/pinpointcampaign";
import ColoredBooleanFields from "../common/ColoredBooleanFields";
import CustomUrlField from "../common/CustomUrlField";

import ColoredNumberField from "./ColoredNumberField";
import { CampaignTypeInput } from "./PinpointCampaignInputs";
const PinpointCampaignFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Name" source="campaignName" alwaysOn resettable />
    <ReferenceInput allowEmpty={false} filterToQuery={(searchText) => ({ title: searchText })} label="Company Survey" reference="survey_companies" source="surveyCompany">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
    <CampaignTypeInput />
  </Filter>
);

const PinpointCampaignList = (props: PinpointCampaign) => (
  <ListGuesser filters={<PinpointCampaignFilters />} {...props} exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
    <FieldGuesser label="Name" source="campaignName" />
    <ReferenceField label="Survey Company" reference="survey_companies" sortBy="surveyCompany.title" source="surveyCompany">
      <TextField source="title" />
    </ReferenceField>
    <ColoredBooleanFields label="Landing Page?" source="isLanding" />
    <CustomUrlField label="Landing Page" sortable={false} source="landingPage" />
    <ReferenceField label="Email Template" reference="emails" sortBy="email.label" source="email">
      <TextField source="label" />
    </ReferenceField>
    <FieldGuesser source="channelType" />
    <FieldGuesser source="campaignType" />
    <DateField source="createdAt" />
    <ColoredNumberField label="Status" source="isExpired" />
  </ListGuesser>
);

export default PinpointCampaignList;
