import * as React from "react";
import { TextField, NumberFieldProps } from "react-admin";

const ColoredNumberField = (props: NumberFieldProps) =>
  props.record && props.source ? (
    props.record[props.source] === "Expired" ? (
      <span style={{ color: "red" }}>
        <TextField {...props} defaultValue="Expired" />
      </span>
    ) : (
      <span style={{ color: "green" }}>
        <TextField {...props} defaultValue="Active" />
      </span>
    )
  ) : null;

ColoredNumberField.defaultProps = TextField.defaultProps;

export default ColoredNumberField;
