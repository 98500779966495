import SurveyFormQuestionIcon from "@material-ui/icons/QuestionAnswer";

import SurveyFormQuestionEdit from "./SurveyFormQuestionEdit";
import SurveyFormQuestionCreate from "./SurveyFormQuestionCreate";
import SurveyFormQuestionList from "./SurveyFormQuestionList";
import SurveyFormQuestionShow from "./SurveyFormQuestionShow";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyFormQuestionList,
  show: SurveyFormQuestionShow,
  create: SurveyFormQuestionCreate,
  edit: SurveyFormQuestionEdit,
  icon: SurveyFormQuestionIcon,
};
