import * as React from "react";
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

import { SocialGroupIssue } from "../interfaces/socialgroupissue";

const SocialGroupIssueCreate = (props: SocialGroupIssue) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
  </CreateGuesser>
);

export default SocialGroupIssueCreate;
