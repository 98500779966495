import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ImageField, Filter, SearchInput } from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

import { Article } from "../interfaces/article";
export const styles: Styles<Theme, any> = {
  image: { maxHeight: "3rem" },
};
const useStyles = makeStyles(styles);

const ArticleFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
  </Filter>
);
const ArticleList = (props: Article) => {
  const classes = useStyles(props);

  return (
    <ListGuesser filters={<ArticleFilter />} {...props} exporter={false}>
      <FieldGuesser source="title" />
      <FieldGuesser label="Link Type" source="type" />
      <ImageField classes={{ image: classes.image }} label="Image" source="contentUrl" />
      <FieldGuesser label="Announcement?" source="announcement" />
      <FieldGuesser label="Published?" source="published" />
    </ListGuesser>
  );
};

export default ArticleList;
