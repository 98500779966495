import SurveyFormStepIcon from "@material-ui/icons/QuestionAnswer";

import SurveyFormStepCreate from "./SurveyFormStepCreate";
import SurveyFormStepEdit from "./SurveyFormStepEdit";
import SurveyFormStepList from "./SurveyFormStepList";
import SurveyFormStepShow from "./SurveyFormStepShow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyFormStepList,
  show: SurveyFormStepShow,
  create: SurveyFormStepCreate,
  edit: SurveyFormStepEdit,
  icon: SurveyFormStepIcon,
};
