import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, SearchInput } from "react-admin";

import { Category } from "../interfaces/category";
import ColoredBooleanFields from "../common/ColoredBooleanFields";

const CategoryFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
  </Filter>
);

const CategoryList = (props: Category) => {
  return (
    <ListGuesser filters={<CategoryFilters />} {...props} exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
      <FieldGuesser source="title" />
      <ColoredBooleanFields source="isActive" />
    </ListGuesser>
  );
};

export default CategoryList;
