/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import React from "react";
import * as ReactDOMClient from "react-dom/client";
// import { createStore, combineReducers, applyMiddleware } from "redux";

// import { Provider } from "react-redux";

// import thunk from "redux-thunk";
// import { reducer as form } from "redux-form";

// import { createBrowserHistory } from "history";
// import { connectRouter, routerMiddleware } from "connected-react-router";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(rootElement!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
