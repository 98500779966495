import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, FunctionField } from "react-admin";

const UserFollowCompanyList = (props: any) => (
  <ListGuesser {...props} exporter={false}>
    <ReferenceField label="User" link={false} reference="users" source="user">
      <FunctionField render={(user: any) => `${user.firstName} ${user.lastName}(${user.email})`} />
    </ReferenceField>
    <ReferenceField label="Company" link={false} reference="companies" source="company">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="followed" />
  </ListGuesser>
);

export default UserFollowCompanyList;
