import * as React from "react";
import { InputGuesser, CreateGuesser } from "@api-platform/admin";
import { FileField, FileInput } from "react-admin";

const ArticleCreate = (props: any) => (
  <CreateGuesser {...props}>
    <InputGuesser source="title" fullWidth />
    {/* <InputGuesser source={"slug"} /> */}
    <InputGuesser source="author" />
    <InputGuesser source="excerpt" />
    <FileInput source="image">
      <FileField source="src" title="title" />
    </FileInput>
    <InputGuesser source="type" />
    <InputGuesser source="content" />
    <InputGuesser source="externalLink" />
    {/* <InputGuesser source={"published"} /> */}
    {/* <InputGuesser source={"userLikes"} /> */}
    <InputGuesser source="nbLikes" />
    {/* <InputGuesser source={"userComments"} /> */}
    <InputGuesser source="nbComments" />
    <InputGuesser source="nbShares" />
    {/* <InputGuesser source={"userShares"} /> */}
    <InputGuesser source="feedItems" />
    <InputGuesser source="highlighted" />
    <InputGuesser source="announcement" />
    <InputGuesser source="benefits" />
    <InputGuesser source="socialGroupIssues" />
    <InputGuesser source="isDiNews" />
  </CreateGuesser>
);

export default ArticleCreate;
