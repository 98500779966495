import * as React from "react";
import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import { RichTextField, ImageField } from "react-admin";

const ArticleShow = (props: any) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="title" addLabel />
    <FieldGuesser source="author" addLabel />
    <RichTextField source="excerpt" addLabel />
    <ImageField source="contentUrl" />
    <FieldGuesser source="type" addLabel />
    <RichTextField source="content" addLabel />
    <FieldGuesser source="externalLink" addLabel />
    <FieldGuesser source="published" addLabel />
    <FieldGuesser source="userLikes" addLabel />
    <FieldGuesser source="nbLikes" addLabel />
    <FieldGuesser source="userComments" addLabel />
    <FieldGuesser source="nbComments" addLabel />
    <FieldGuesser source="nbShares" addLabel />
    <FieldGuesser source="userShares" addLabel />
    <FieldGuesser source="announcement" addLabel />
    <FieldGuesser source="benefits" addLabel />
  </ShowGuesser>
);

export default ArticleShow;
