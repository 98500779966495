import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, SearchInput } from "react-admin";

const PageFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="pageTitle" alwaysOn resettable />
  </Filter>
);

const PageList = (props: any) => (
  <ListGuesser filters={<PageFilter />} {...props} exporter={false} {...props}>
    <FieldGuesser source="pageTitle" />
    <FieldGuesser source="slug" />
  </ListGuesser>
);

export default PageList;
