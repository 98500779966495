import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LabelIcon from "@material-ui/icons/Label";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, ReduxState } from "react-admin";

import articles from "../articles";
import industries from "../industries";
import employeesRanges from "../employees_ranges";
import benefits from "../benefits";
import categories from "../categories";
import users from "../users";
import surveyFormQuestions from "../survey_form_questions";
import socialGroupIssues from "../social_group_issues";
import surveyStepQuestion from "../survey_form_steps";
import companies from "../companies";
import pinpointCampaigns from "../pinpoint_campaigns";
import campaignSchedules from "../campaign_schedules";
import surveyCompanies from "../survey_companies";
import { AppState } from "../interfaces/types";
import pinpointCampaignUsers from "../pinpoint_campaign_users";
import mediaImages from "../media_images";
import admin from "../admin";
import userActivityLogs from "../user_activity_logs";
import pages from "../pages";

import SubMenu from "./SubMenu";

type MenuName = "menuArticle" | "menuSurvey" | "menuUser" | "menuSpecificity" | "menuCompany" | "menuPage";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuArticle: false,
    menuSpecificity: false,
    menuCompany: false,
    menuSurvey: true,
    menuUser: false,
    menuPage: false,
  });
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: 280,
    },
    closed: {
      width: 55,
    },
  }));

  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <DashboardMenuItem />
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuArticle")} icon={<articles.icon />} isOpen={state.menuArticle} name="pos.menu.article">
        <MenuItemLink
          dense={dense}
          leftIcon={<articles.icon />}
          primaryText={translate(`resources.article.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/articles",
            state: { _scrollToTop: true },
          }}
        />
        {/* <MenuItemLink
          to={{
            pathname: "/invoices",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        /> */}
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuSpecificity")} icon={<industries.icon />} isOpen={state.menuSpecificity} name="pos.menu.specificity">
        <MenuItemLink
          dense={dense}
          leftIcon={<industries.icon />}
          primaryText={translate(`resources.specificity.industries.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/industries",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<employeesRanges.icon />}
          primaryText={translate(`resources.specificity.employeeRanges.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/employees_ranges",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<benefits.icon />}
          primaryText={translate(`resources.specificity.benefits.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/benefits",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<socialGroupIssues.icon />}
          primaryText={translate(`resources.specificity.socialGroupIssues.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/social_group_issues",
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuCompany")} icon={<companies.icon />} isOpen={state.menuCompany} name="pos.menu.company">
        <MenuItemLink
          dense={dense}
          leftIcon={<companies.icon />}
          primaryText={translate(`resources.company.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/companies",
            state: { _scrollToTop: true },
          }}
        />
        {/* <MenuItemLink
          to={{
            pathname: "/invoices",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        /> */}
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuSurvey")} icon={<categories.icon />} isOpen={state.menuSurvey} name="pos.menu.survey">
        <MenuItemLink
          dense={dense}
          leftIcon={<categories.icon />}
          primaryText={translate(`resources.survey.surveyCategory.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/survey_forms",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyStepQuestion.icon />}
          primaryText={translate(`resources.survey.surveyStep.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/survey_form_steps",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<categories.icon />}
          primaryText={translate(`resources.survey.questionCategory.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/categories",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyFormQuestions.icon />}
          primaryText={translate(`resources.survey.surveyQuestion.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/survey_form_questions",
            state: { _scrollToTop: true },
          }}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<surveyCompanies.icon />}
          primaryText={translate(`resources.survey.companyGenericSurveys.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/survey_companies",
            state: { _scrollToTop: true },
          }}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<pinpointCampaigns.icon />}
          primaryText={translate(`resources.survey.pinpointCampaigns.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/pinpoint_campaigns",
            state: { _scrollToTop: true },
          }}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<campaignSchedules.icon />}
          primaryText={translate(`resources.survey.campaignSchedules.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/campaign_schedules",
            state: { _scrollToTop: true },
          }}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<mediaImages.icon />}
          primaryText={translate(`resources.survey.mediaImages.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/media_images",
            state: { _scrollToTop: true },
          }}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<pinpointCampaignUsers.icon />}
          primaryText={translate(`resources.survey.pinpointCampaignUsers.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/pinpoint_campaign_users",
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuUser")} icon={<users.icon />} isOpen={state.menuUser} name="pos.menu.user">
        <MenuItemLink
          dense={dense}
          leftIcon={<users.icon />}
          primaryText={translate(`resources.user.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/users",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<userActivityLogs.icon />}
          primaryText={translate(`resources.user.userActivityLogs.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/user_activity_logs",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<admin.icon />}
          primaryText={translate(`resources.user.companyAdmin.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/admins",
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<LabelIcon />}
          primaryText={translate(`resources.user.userFollows.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/user_follow_companies",
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle("menuPage")} icon={<pages.icon />} isOpen={state.menuPage} name="pos.menu.pages">
        <MenuItemLink
          dense={dense}
          leftIcon={<pages.icon />}
          primaryText={translate(`resources.pages.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: "/pages",
            state: { _scrollToTop: true },
          }}
        />
        {/* <MenuItemLink
          to={{
            pathname: "/invoices",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        /> */}
      </SubMenu>
    </div>
  );
};

export default Menu;
