import * as React from "react";
import { FieldGuesser, ShowGuesser } from "@api-platform/admin";

import { Benefit } from "../interfaces/benefit";

const BenefitShow = (props: Benefit) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel />
  </ShowGuesser>
);

export default BenefitShow;
