import PinpointCampaignIcon from "@material-ui/icons/AssignmentTurnedIn";

import PinpointCampaignEdit from "./PinpointCampaignEdit";
import PinpointCampaignCreate from "./PinpointCampaignCreate";
import PinpointCampaignList from "./PinpointCampaignList";
import PinpointCampaignShow from "./PinpointCampaignShow";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: PinpointCampaignList,
  create: PinpointCampaignCreate,
  edit: PinpointCampaignEdit,
  show: PinpointCampaignShow,
  icon: PinpointCampaignIcon,
};
