import * as React from "react";
import { InputProps, ReferenceField, SelectInput, SimpleFormIterator, FormDataConsumer, NumberInput, BooleanInput, TextField, number, minValue, maxValue, ArrayInput, useRecordContext } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

import { SurveyCompanySurveyFormQuestion } from "../interfaces/surveycompanysurveyformquestion";

import iterationStyle from "./iterationStyle";

export const styles: Styles<Theme, any> = {
  surveyFormQuestion: { display: "inline-block", marginRight: "1rem", width: "35%" },
  label: { display: "inline-block", marginRight: "1rem", width: "25em" },
  rank: { display: "inline-block", marginRight: "1rem", minWidth: 60, maxWidth: 80 },
  maxAnswerSelection: { display: "inline-block", marginRight: "1rem", marginLeft: "1rem", width: 100 },
  isRequired: { display: "inline-block", marginRight: "1rem", width: 160 },
  isVisible: { display: "inline-block", marginRight: "1rem", width: 160 },
  typeRestriction: { display: "inline-block", marginRight: "1rem", width: 120 },
  info: { display: "inline-block", marginRight: "1rem", marginLeft: "1rem", width: 300 },
  surveyCompanyQuestions: { display: "flex" },
};
const useStyles = makeStyles(styles);
type Props = {
  source?: string;
} & Omit<InputProps, "source">;

const validateRank = [number(), minValue(0), maxValue(2000)];

const SurveyCompanyArrayInput = ({ ...rest }: Props) => {
  const classes = useStyles(rest);
  const record = useRecordContext(rest);

  if (!record) return null;

  return (
    <ArrayInput
      defaultValue={record?.surveyCompanyQuestions?.filter((item: SurveyCompanySurveyFormQuestion, index: number) => {
        item.rank = item.rank === 0 ? index * 5 : item.rank;

        return item;
      })}
      source="surveyCompanyQuestions"
    >
      <SimpleFormIterator classes={{ form: classes.surveyCompanyQuestions }} disableAdd disableRemove disableReordering>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return scopedFormData && scopedFormData.surveyFormQuestion ? (
              <div key={scopedFormData.id} style={iterationStyle(scopedFormData)}>
                <ReferenceField className={classes.label} reference="survey_form_questions" source={getSource?.("surveyFormQuestion") as string}>
                  <TextField source="label" style={{ width: "25em", marginRight: "1rem" }} />
                </ReferenceField>{" "}
                <NumberInput className={classes.rank} label="Rank" source={getSource?.("rank") as string} validate={validateRank} />
                <BooleanInput className={classes.isRequired} label="Is Required?" source={getSource?.("isRequired") as string} />
                <BooleanInput className={classes.isVisible} label="Is Visible?" source={getSource?.("isVisible") as string} />
                <RichTextInput formClassName={classes.info} fullWidth={false} label="Info" source={getSource?.("info") as string} />
              </div>
            ) : null;
          }}
        </FormDataConsumer>

        <FormDataConsumer className={classes.maxAnswerSelection} label="Max Selection" fullWidth>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            const choiceGenerator = (scopedFormData: any): Array<any> => {
              const arr: Array<any> = [];
              arr.push({ id: "", name: "Max Selection" });
              arr.push({ id: 0, name: "All" });

              for (let i = 1; i < scopedFormData.totalAns; i++) {
                const obj: any = {};
                obj["id"] = i;
                obj["name"] = `${i}`;
                arr.push(obj);
              }

              return arr;
            };

            return scopedFormData && (scopedFormData.questionType === "choice-multiple-expanded" || scopedFormData.questionType === "choice-multiple-collapsed") ? <SelectInput choices={choiceGenerator(scopedFormData)} className={classes.maxAnswerSelection} label="Max Selection" optionText="name" optionValue="id" source={getSource?.("maxAnswerSelection")} {...rest} /> : null;
          }}
        </FormDataConsumer>
        <FormDataConsumer className={classes.typeRestriction} label="Type Restriction" fullWidth>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            return (scopedFormData && scopedFormData.questionType === "text") || scopedFormData.questionType === "textarea" ? (
              <SelectInput
                choices={[
                  { id: "text", name: "Text" },
                  { id: "number", name: "Number" },
                  { id: "percentage", name: "Percentage" },
                ]}
                className={classes.typeRestriction}
                label="Select Restriction"
                optionText="name"
                optionValue="id"
                source={getSource?.("typeRestriction")}
                {...rest}
              />
            ) : null;
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

SurveyCompanyArrayInput.defaultProps = {
  addField: true,
  source: "surveyCompanyQuestions",
  resource: "survey_companies",
};
export { SurveyCompanyArrayInput };
