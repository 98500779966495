import * as React from "react";
import { SelectInput, InputProps, required } from "react-admin";

import { campaignsFormTypes, campaignTypes, channelTypes, frequencyTypes, messageTypes, retargetTypes, scheduleStartTypes } from "./pinpoint-campaign-drop-down-data";
type Props = {
  source?: string;
} & Omit<InputProps, "source">;
const CampaignsFormTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={campaignsFormTypes.map((campaignsFormType) => ({
        id: campaignsFormType.id,
        name: campaignsFormType.name,
      }))}
      defaultValue="Popup"
      label="Display Type"
      source="formType"
      validate={[required()]}
    />
  );
};

CampaignsFormTypeInput.defaultProps = {
  source: "formType",
  resource: "pinpoint_campaigns",
};

const CampaignTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={campaignTypes.map((campaignType) => ({
        id: campaignType.id,
        name: campaignType.name,
      }))}
      defaultValue="Company"
      source="campaignType"
      validate={[required()]}
    />
  );
};

CampaignTypeInput.defaultProps = {
  source: "campaignType",
  resource: "pinpoint_campaigns",
};

const RetargetTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={retargetTypes.map((retargetType) => ({
        id: retargetType.id,
        name: retargetType.name,
      }))}
      defaultValue="None"
      source="retargetType"
      validate={[required()]}
    />
  );
};

RetargetTypeInput.defaultProps = {
  source: "retargetType",
  resource: "pinpoint_campaigns",
};

const ChannelTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={channelTypes.map((channelType) => ({
        id: channelType.id,
        name: channelType.name,
      }))}
      defaultValue="EMAIL"
      source="channelType"
      validate={[required()]}
    />
  );
};

ChannelTypeInput.defaultProps = {
  source: "channelType",
  resource: "pinpoint_campaigns",
};

const ScheduleFrequencyInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={frequencyTypes.map((scheduleFrequency) => ({
        id: scheduleFrequency.id,
        name: scheduleFrequency.name,
      }))}
      defaultValue="ONCE"
      label="Frequency"
      source="scheduleFrequency"
      validate={[required()]}
    />
  );
};

ScheduleFrequencyInput.defaultProps = {
  source: "scheduleFrequency",
  resource: "pinpoint_campaigns",
};

const ScheduleStartTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={scheduleStartTypes.map((scheduleStarttype) => ({
        id: scheduleStarttype.id,
        name: scheduleStarttype.name,
      }))}
      defaultValue="Immediately"
      label="Start Type"
      source="scheduleStarttype"
      validate={[required()]}
    />
  );
};

ScheduleStartTypeInput.defaultProps = {
  source: "scheduleFrequency",
};

const SmsMessagetypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={messageTypes.map((smsMessagetype) => ({
        id: smsMessagetype.id,
        name: smsMessagetype.name,
      }))}
      label="Message Type"
      source="smsMessagetype"
      style={{ display: "inline-block", marginRight: "1rem", width: 360 }}
    />
  );
};

SmsMessagetypeInput.defaultProps = {
  source: "smsMessagetype",
};

export { CampaignsFormTypeInput, ChannelTypeInput, CampaignTypeInput, RetargetTypeInput, ScheduleFrequencyInput, ScheduleStartTypeInput, SmsMessagetypeInput };
