import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";

import { Benefit } from "../interfaces/benefit";

const BenefitTitle = (props: any) => {
  return <span>Benefit {props.record ? `"${props.record.name}"` : ""}</span>;
};

const BenefitEdit = (props: Benefit) => (
  <EditGuesser title={<BenefitTitle />} {...props}>
    <InputGuesser source="name" />
  </EditGuesser>
);

export default BenefitEdit;
