import CategoryIcon from "@material-ui/icons/Category";

import CategoryList from "./CategoryList";
import CategoryEdit from "./CategoryEdit";
import CategoryCreate from "./CategoryCreate";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: CategoryList,
  edit: CategoryEdit,
  create: CategoryCreate,
  icon: CategoryIcon,
};
