import * as React from "react";
import { CreateGuesser, InputGuesser } from "@api-platform/admin";

const LocaleCreate = (props: any) => (
  <CreateGuesser {...props}>
    <InputGuesser source="code" />
    <InputGuesser source="name" />
    <InputGuesser source="isDefault" />
  </CreateGuesser>
);

export default LocaleCreate;
