import SurveyCompanyIcon from "@material-ui/icons/ReceiptTwoTone";

import SurveyCompanySurveyFormQuestionList from "./SurveyCompanySurveyFormQuestionList";
import SurveyCompanySurveyFormQuestionEdit from "./SurveyFormStepSurveyFormQuestionEdit";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyCompanySurveyFormQuestionList,
  edit: SurveyCompanySurveyFormQuestionEdit,
  // create: SurveyCompanyCreate,
  // show: SurveyCompanyShow,
  icon: SurveyCompanyIcon,
};
