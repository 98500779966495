import * as React from "react";
import { DialogContent, Typography } from "@material-ui/core";
import { SaveButton, Confirm, Labeled } from "react-admin";
import { useFormState } from "react-final-form";
import Moment from "moment";

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT || "";

const ConfirmationDialog = (props: any) => {
  return (
    <DialogContent>
      <Labeled label="Campaign Name" fullWidth>
        <Typography gutterBottom>{props.campaignName}</Typography>
      </Labeled>
      <Labeled label="Company" fullWidth>
        <Typography gutterBottom>{props.company}</Typography>
      </Labeled>
      <Labeled label="Start Time" fullWidth>
        <Typography gutterBottom>{Moment(props?.scheduleStarttime.date).format("LLLL")}</Typography>
      </Labeled>
      <Labeled label="Total Recipients" fullWidth>
        <Typography gutterBottom>{props.totalRecipients}</Typography>
      </Labeled>
      <Labeled label="Recipients" fullWidth>
        <Typography gutterBottom>{props.recipients}</Typography>
      </Labeled>
    </DialogContent>
  );
};

const confirmationData = async (props: any) => {
  const url = props.originId ? `${ENTRYPOINT}/pinpoint_campaigns/${props.originId}/confirmation` : `${ENTRYPOINT}/pinpoint_campaigns/confirmation`;
  const request = new Request(url, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("token")}` }),
    body: JSON.stringify(props),
  });

  return await fetch(request).then((response) => response.json());
};

const SubmitPinpointCampaignButton = ({ handleSubmitWithRedirect, handleSubmit, ...props }: any) => {
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({});
  const { values, invalid } = useFormState();

  const handleClick = () => {
    if (!invalid) {
      confirmationData(values).then((res: any) => {
        setDialogData(res);
        setOpen(true);
      });
    } else {
      handleSubmit();
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  return (
    <>
      <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />
      <Confirm cancel="Cancel" confirm="Send" content={<ConfirmationDialog {...dialogData} />} isOpen={open} title="Pinpoint Campaign deployment confirmation" onClose={handleDialogClose} onConfirm={handleConfirm} />
    </>
  );
};

export default SubmitPinpointCampaignButton;
