import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";
// eslint-disable-next-line import/order
import { ReferenceInput, SelectInput, AutocompleteArrayInput, ReferenceArrayInput, number, minValue, maxValue } from "react-admin";

// import { makeStyles, Theme } from "@material-ui/core/styles";
// import { Styles } from "@material-ui/styles/withStyles";
import RichTextInput from "ra-input-rich-text";

import { SurveyFormStep } from "../interfaces/surveyformstep";
// export const styles: Styles<Theme, any> = {
//   inlineBlock: { display: "inline-flex", marginRight: "1rem" },
// };

const validatePosition = [number(), minValue(1), maxValue(1000)];
// const useStyles = makeStyles(styles);
const SurveyFormStepEdit = (props: SurveyFormStep) => {
  // const classes = useStyles(props);

  return (
    <EditGuesser {...props}>
      <ReferenceInput label="Survey Name" reference="survey_forms" source="form">
        <SelectInput optionText="title" optionValue="@id" />
      </ReferenceInput>
      <InputGuesser source="title" />
      <RichTextInput source="description" />
      <InputGuesser source="position" validate={validatePosition} />
      <ReferenceArrayInput label="Survey Questions" reference="survey_form_questions" source="formQuestions">
        <AutocompleteArrayInput optionText="label" optionValue="@id" />
      </ReferenceArrayInput>
    </EditGuesser>
  );
};

export default SurveyFormStepEdit;
