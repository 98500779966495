import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, Filter, FunctionField, ReferenceInput, AutocompleteInput, SearchInput } from "react-admin";

const UserActivityLogFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Activity" source="activity" alwaysOn resettable />
    <ReferenceInput filterToQuery={(searchText) => ({ title: searchText })} label="Survey Companies" reference="survey_companies" source="surveyCompany">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
  </Filter>
);

const UserActivityLogList = (props: any) => (
  <ListGuesser filters={<UserActivityLogFilters />} {...props} exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
    <ReferenceField label="User Name" link={false} reference="users" sortBy="user.firstName" source="user">
      <FunctionField render={(user: any) => `${user.firstName} ${user.lastName}`} />
    </ReferenceField>
    <ReferenceField label="Survey Company" reference="survey_companies" sortBy="surveyCompany.id" source="surveyCompany">
      <TextField source="title" />
    </ReferenceField>
    <FieldGuesser source="activity" />
    <FieldGuesser source="old_value" />
    <FieldGuesser source="new_value" />
    <FieldGuesser source="createdAt" />
  </ListGuesser>
);

export default UserActivityLogList;
