import BenefitIcon from "@material-ui/icons/ReceiptTwoTone";

import BenefitEdit from "./BenefitEdit";
import BenefitList from "./BenefitList";
import BenefitShow from "./BenefitShow";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: BenefitList,
  show: BenefitShow,
  edit: BenefitEdit,
  icon: BenefitIcon,
};
