import * as React from "react";
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

import { EmployeesRange } from "../interfaces/employeesrange";

const EmployeesRangeCreate = (props: EmployeesRange) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="position" />
  </CreateGuesser>
);

export default EmployeesRangeCreate;
