import polyglotI18nProvider from "ra-i18n-polyglot";

import englishMessages from "./en";
const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./fr").then((messages) => messages.default);
  }

  return englishMessages;
}, "en");

export default i18nProvider;
