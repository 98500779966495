import * as React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, required } from "react-admin";

const AdminCreate = (props: any) => (
  <CreateGuesser {...props}>
    <ReferenceInput filterToQuery={(searchText: any) => ({ email: searchText })} label="User" perPage={10} reference="users" source="user" validate={[required()]} fullWidth>
      <AutocompleteInput optionText="fullNameWithEmail" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput filter={{ validated: true }} filterToQuery={(searchText: any) => ({ name: searchText })} label="Company" reference="companies" source="company" validate={[required()]} fullWidth>
      <AutocompleteInput />
    </ReferenceInput>
  </CreateGuesser>
);

export default AdminCreate;
