import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput } from "react-admin";
import RichTextInput from "ra-input-rich-text";
const SurveyCompanySurveyFormQuestionEdit = (props: any) => (
  <EditGuesser {...props}>
    <ReferenceInput reference="survey_companies" source="surveyCompany">
      <SelectInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput reference="survey_form_questions" source="surveyFormQuestion">
      <SelectInput optionText="label" />
    </ReferenceInput>
    <ReferenceInput reference="survey_form_questions" source="parentSurveyFormQuestion">
      <SelectInput optionText="label" />
    </ReferenceInput>
    <InputGuesser source="rank" />
    <RichTextInput source="info" />
    <InputGuesser source="level" />
    <InputGuesser source="isRequired" />
    <InputGuesser source="maxAnswerSelection" />
    <InputGuesser source="isVisible" />
  </EditGuesser>
);

export default SurveyCompanySurveyFormQuestionEdit;
