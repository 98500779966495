import CampaignScheduleIcon from "@material-ui/icons/EventAvailable";

import CampaignScheduleEdit from "./CampaignSchedulesEdit";
import CampaignScheduleList from "./CampaignSchedulesList";
import CampaignScheduleShow from "./CampaignSchedulesShow";

export default {
  icon: CampaignScheduleIcon,
  list: CampaignScheduleList,
  edit: CampaignScheduleEdit,
  show: CampaignScheduleShow,
};
