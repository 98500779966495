import * as React from "react";
import { ListGuesser } from "@api-platform/admin";
import { DateField, ReferenceField, TextField, Filter, AutocompleteInput, ReferenceInput } from "react-admin";

const AdminFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput filter={{ enable: true }} filterToQuery={(searchText: any) => ({ email: searchText })} label="User" perPage={10} reference="users" source="user" alwaysOn fullWidth resettable>
      <AutocompleteInput optionText="fullNameWithEmail" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput filter={{ validated: true }} filterToQuery={(searchText: any) => ({ name: searchText })} label="Company" reference="companies" source="company" fullWidth>
      <AutocompleteInput optionText="name" optionValue="@id" />
    </ReferenceInput>
  </Filter>
);

const AdminList = (props: any) => (
  <ListGuesser exporter={false} filters={<AdminFilters />} hasShow={false} sort={{ field: "createdAt", order: "DESC" }} {...props}>
    <ReferenceField label="User" link={false} reference="users" sortBy="user.email" source="user">
      <TextField source="fullNameWithEmail" />
    </ReferenceField>
    <ReferenceField label="Company" link={false} reference="companies" sortBy="company.name" source="company">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="createdAt" />
  </ListGuesser>
);

export default AdminList;
