import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, SearchInput } from "react-admin";

const BenefitFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Name" source="name" alwaysOn resettable />
  </Filter>
);
const BenefitList = (props: any) => (
  <ListGuesser filters={<BenefitFilter />} {...props} exporter={false}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

export default BenefitList;
