import * as React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { RichTextField, ImageField, useRecordContext, SimpleShowLayout, TextField, BooleanField, UrlField, DateField } from "react-admin";

import { ScheduleFrequencyField, ScheduleStarttypeField, FormTypeField, ChannelTypeField, CampaignTypeField, RetargetTypeField } from "./PinpointCampaignFields";
const SurveyFormQuestionShowLayout = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout>
      <TextField label="Name" source="campaignName" addLabel />
      <TextField label="Campaign Id" source="campaignId" addLabel />
      <TextField label="Deployment Id" source="deploymentId" addLabel />
      <TextField label="Segment Id" source="segmentId" addLabel />
      {record.isLanding && <UrlField label="Landing Page" source="landingPage" addLabel />}
      {!record.isLanding && <BooleanField label="Landing?" source="isLanding" addLabel />}
      <ScheduleFrequencyField label="Schedule Frequency" />
      <ScheduleStarttypeField label="Schedule Start Type" />
      <DateField label="Start Time" source="scheduleStarttime" options={{ weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", timeZone: record.scheduleTimezone }} addLabel showTime />
      <DateField label="End Time" source="scheduleEndtime" addLabel />
      <TextField label="Timezone" source="scheduleTimezone" addLabel />
      <ChannelTypeField label="Channel Type" />
      <FormTypeField label="Display Type" />
      <CampaignTypeField label="Campaign Type" />
      {record.image && record.image?.imageAlt && <TextField label="Image Alt Text" source="image.imageAlt" addLabel />}
      {record.image && record.image?.imageUrl && <ImageField label="Image" source="image.imageUrl" addLabel />}
      {record.image && record.image?.loginTextTop && <RichTextField label="Login Page Top Text" source="image.loginTextTop" addLabel />}
      {record.image && record.image?.loginTextBottom && <RichTextField label="Login Page Bottom Text" source="image.loginTextBottom" addLabel />}
      <RetargetTypeField label="Retarget Type" />
      <BooleanField label="Position Selection" source="isPositionSelection" addLabel />
      <BooleanField label="Department Selection" source="isDepartmentSelection" addLabel />
      <BooleanField label="BirthDate Selection" source="isBirthDateSelection" addLabel />
      <BooleanField label="Department Selection" source="isEthnicitySelection" addLabel />
      <BooleanField label="Ethnicity Selection" source="isRaceSelection" addLabel />
      <BooleanField label="Gender Selection" source="isGenderSelection" addLabel />
      <BooleanField label="Language Selection" source="isLanguageSelection" addLabel />
      <BooleanField label="Send mail to associated user?" source="mailAssociatedUser" addLabel />
      <TextField label="First Cycle Questions" source="quesCountFirst" addLabel />
      <TextField label="Alternate Cycle Questions" source="quesCountAlternate" addLabel />
      <TextField label="Email Title" source="emailTitle" addLabel />
      <TextField label="Email From Name" source="emailFromName" addLabel />
      <TextField label="From Email" source="emailFrom" addLabel />
      <TextField label="Test Email(s)" source="emailCC" addLabel />
      {record.emailBody && record.channelType === "EMAIL" && <TextField source="emailBody" addLabel />}
      {record.emailHtmlbody && record.channelType === "EMAIL" && <RichTextField label="HTML Body" source="emailHtmlbody" addLabel />}
      {record.smsBody && record.channelType === "SMS" && <FieldGuesser label="SMS Body" source="smsBody" addLabel />}
      <DateField source="createdAt" addLabel />
    </SimpleShowLayout>
  );
};

const PinpointCampaignTitle = () => {
  const record = useRecordContext();

  if (!record) return null;

  return <span>Pinpoint Campaign {record.campaignName}</span>;
};

const PinpointCampaignShow = (props: any) => (
  <ShowGuesser title={<PinpointCampaignTitle />} {...props}>
    <SurveyFormQuestionShowLayout />
  </ShowGuesser>
);

export default PinpointCampaignShow;
