import * as React from "react";
import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import { ReferenceArrayField, ReferenceField, SingleFieldList, TextField, ChipField } from "react-admin";

const CampaignScheduleShow = (props: any) => (
  <ShowGuesser {...props}>
    <ReferenceField label="User" reference="pinpoint_campaigns" source="pinpointCampaign">
      <TextField source="campaignName" />
    </ReferenceField>
    <FieldGuesser options={{ weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", timeZone: "CST" }} source="scheduledAt" addLabel />
    <FieldGuesser source="isCompleted" addLabel />
    <FieldGuesser source="totalRecipients" addLabel />

    <ReferenceArrayField label="Recipients" reference="schedule_recipients" source="scheduleRecipients">
      <SingleFieldList linkType={false}>
        <ChipField source="recipientNameEmail" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ShowGuesser>
);

export default CampaignScheduleShow;
