import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";
import RichTextInput from "ra-input-rich-text";

const CategoryTitle = (props: any) => {
  return <span>Category {props.record ? `"${props.record.title}"` : ""}</span>;
};

const CategoryEdit = (props: any) => (
  <EditGuesser title={<CategoryTitle />} {...props}>
    <InputGuesser source="title" />
    <RichTextInput source="description" />
    <InputGuesser source="isActive" />
  </EditGuesser>
);

export default CategoryEdit;
