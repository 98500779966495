import * as React from "react";
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, SimpleFormIterator, TextInput, AutocompleteInput, required, minLength, DateInput, BooleanInput, maxLength } from "react-admin";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { Box } from "@material-ui/core";

import { SurveyCompany } from "../interfaces/surveycompany";

export const styles: Styles<Theme, any> = {
  title: { display: "inline-block", marginRight: "1rem", width: 720 },
  company: { display: "inline-block", marginRight: "1rem", width: 360 },
  deploymentType: { display: "inline-block", marginRight: "1rem", width: 360 },
  surveyForms: { display: "inline-block", marginRight: "1rem", minWidth: 360, maxWidth: 720 },
  inlineBlock: { display: "inline-block", marginRight: "1rem", width: 360 },
  surveyCompanyQuestions: { display: "block" },
};
const useStyles = makeStyles(styles);
const validateRequired = [required()];

const SurveyCompanyTitle = () => {
  return <span>Add Company/Generic Survey</span>;
};

const Separator = () => <Box pt="1em" />;

const SurveyCompanyCreate = (props: SurveyCompany) => {
  const classes = useStyles(props);

  return (
    <CreateGuesser title={<SurveyCompanyTitle />} {...props}>
      <InputGuesser formClassName={classes.title} source="title" validate={[required(), minLength(5), maxLength(120)]} fullWidth />

      <Separator />
      <ReferenceInput formClassName={classes.deploymentType} label="Deployment Type" reference="deployment_types" source="deploymentType" validate={validateRequired} fullWidth>
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} formClassName={classes.inlineBlock} label="Company" reference="companies" source="company" fullWidth>
        <AutocompleteInput optionText="name" optionValue="@id" />
      </ReferenceInput>

      <Separator />
      <ReferenceArrayInput filterToQuery={(searchText: any) => ({ title: searchText })} formClassName={classes.inlineBlock} label="Categories" reference="survey_forms" source="surveyForms" validate={validateRequired} fullWidth>
        <AutocompleteArrayInput optionText="title" optionValue="@id" />
      </ReferenceArrayInput>

      <Separator />
      <DateInput defaultValue={new Date()} formClassName={classes.inlineBlock} source="startDate" validate={validateRequired} fullWidth />
      <DateInput formClassName={classes.inlineBlock} source="endDate" validate={validateRequired} fullWidth />

      <Separator />
      <BooleanInput label="Is Active?" source="isActive" />

      <Separator />
      <ArrayInput source="surveyCompanyTokens">
        <SimpleFormIterator>
          <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} formClassName={classes.inlineBlock} label="Token" reference="tokens" source="token" validate={[required()]}>
            <SelectInput />
          </ReferenceInput>
          <TextInput formClassName={classes.inlineBlock} label="Value" source="replaceValue" validate={[required(), minLength(2), maxLength(120)]} />
        </SimpleFormIterator>
      </ArrayInput>
    </CreateGuesser>
  );
};

export default SurveyCompanyCreate;
