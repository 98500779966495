import * as React from "react";
import { Card, CardContent } from "@material-ui/core";
import { Title } from "react-admin";

// eslint-disable-next-line import/no-anonymous-default-export, react/display-name
export default () => (
  <Card>
    <Title title="Not Found" />
    <CardContent>
      <h1>404: Page not found</h1>
    </CardContent>
  </Card>
);
