import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, Filter, SearchInput, ReferenceInput, AutocompleteInput } from "react-admin";

import { Company } from "../interfaces/company";
import ColoredBooleanFields from "../common/ColoredBooleanFields";

const CompanyFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Name" source="name" alwaysOn resettable />
    <ReferenceInput label="Industry" reference="industries" source="industry">
      <AutocompleteInput />
    </ReferenceInput>
  </Filter>
);

const CompanyList = (props: Company) => {
  return (
    <ListGuesser filters={<CompanyFilter />} {...props} exporter={false}>
      <FieldGuesser source="name" />
      <ReferenceField label="Industry" link={false} reference="industries" source="industry">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser label="Followers" source="nbFollowers" />
      <FieldGuesser label="Score" source="globalAverage" />
      <ColoredBooleanFields label="Logo" sortable={false} source="isLogo" />
      <ColoredBooleanFields label="Hero" sortable={false} source="isHero" />
      <ColoredBooleanFields label="Paid?" source="hasSubscription" />
      <ColoredBooleanFields label="Featured" source="featured" />
      <ColoredBooleanFields label="Validated" source="validated" />
      <ColoredBooleanFields label="Paternity" source="isPaternityLeave" />
      <ColoredBooleanFields label="Maternity" source="isMaternityLeave" />
      <ColoredBooleanFields label="Adoptive" source="isAdoptiveLeave" />
      <ColoredBooleanFields label="Time Off" source="isPaidTimeOff" />
      <ColoredBooleanFields label="Demographics" source="isDemographics" />
      <ColoredBooleanFields label="Jobs?" source="isPostJobs" />
      <FieldGuesser label="Completion %" source="completionPercentage" />
    </ListGuesser>
  );
};

export default CompanyList;
