import * as React from "react";
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteArrayInput, DateTimeInput, ReferenceInput, SelectInput, ReferenceArrayInput } from "react-admin";

const CampaignScheduleEdit = (props: any) => (
  <EditGuesser {...props}>
    <ReferenceInput label="Pinpoint Campaign" reference="pinpoint_campaigns" source="pinpointCampaign">
      <SelectInput optionText="campaignName" optionValue="@id" />
    </ReferenceInput>
    <DateTimeInput source="scheduledAt" />
    <InputGuesser source="isCompleted" />

    <ReferenceArrayInput filterToQuery={(searchText: string) => ({ user: { email: searchText } })} reference="schedule_recipients" source="scheduleRecipients" fullWidth>
      <AutocompleteArrayInput optionText="recipientNameEmail" optionValue="@id" />
    </ReferenceArrayInput>
  </EditGuesser>
);

export default CampaignScheduleEdit;
