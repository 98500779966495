import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

// eslint-disable-next-line import/no-anonymous-default-export, react/display-name
export default () => (
  <Card>
    <CardHeader title="Welcome to the Kanarys Administration" />
    <CardContent>This section only accessible to admin users</CardContent>
  </Card>
);
