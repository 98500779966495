import * as React from "react";
import { InputGuesser, EditGuesser } from "@api-platform/admin";
import RichTextInput from "ra-input-rich-text";
import { ImageField, ImageInput } from "react-admin";

const ArticleEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser source="title" fullWidth />
    <RichTextInput source="excerpt" />
    <RichTextInput source="content" />
    <ImageInput accept="image/*" label="Image" source="image">
      <ImageField source="src" title="title" />
    </ImageInput>
    <InputGuesser source="type" />
    <InputGuesser source="published" />
    <InputGuesser source="announcement" />
  </EditGuesser>
);

export default ArticleEdit;
