import SurveyCompanyIcon from "@material-ui/icons/ReceiptTwoTone";

import SurveyFormStepSurveyFormQuestionList from "./SurveyFormStepSurveyFormQuestionList";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyFormStepSurveyFormQuestionList,
  // edit: SurveyCompanySurveyFormQuestionEdit,
  icon: SurveyCompanyIcon,
};
