import * as React from "react";
import { FieldGuesser, ShowGuesser } from "@api-platform/admin";

import { SocialGroupIssue } from "../interfaces/socialgroupissue";

const SocialGroupIssueShow = (props: SocialGroupIssue) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel />
  </ShowGuesser>
);

export default SocialGroupIssueShow;
