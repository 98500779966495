import * as React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, SearchInput } from "react-admin";

import { SurveyForm } from "../interfaces/surveyform";
const SurveyFormFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
  </Filter>
);
const SurveyFormList = (props: SurveyForm) => (
  <ListGuesser filters={<SurveyFormFilters />} {...props} exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
    <FieldGuesser source="title" />
    <FieldGuesser source="slug" />
  </ListGuesser>
);

export default SurveyFormList;
