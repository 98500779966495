import * as React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { ArrayField, Datagrid, TextField, RichTextField } from "react-admin";

const PageShow = (props: any) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="pageTitle" addLabel />
    <FieldGuesser source="slug" addLabel />
    <ArrayField source="pageSections">
      <Datagrid>
        <TextField source="title" />
        <RichTextField source="content" />
      </Datagrid>
    </ArrayField>
    <FieldGuesser source="createdAt" addLabel />
    <FieldGuesser source="updatedAt" addLabel />
  </ShowGuesser>
);
export default PageShow;
