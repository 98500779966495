import * as React from "react";
import { FunctionField, useRecordContext } from "react-admin";

import { employeeStatuses, familyStatuses, nativeLanguages, oauthProviders, privacies, roles, userTypes } from "./user-dropdown-data";

const RoleField = ({ source }: any) => {
  const record = useRecordContext();
  const role: any = roles.find((item: any) => record[source] === item.id);

  return role ? <FunctionField label="Role" render={() => `${role.name}`} /> : null;
};

RoleField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "role",
};

const UserTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const userType: any = userTypes.find((item: any) => record[source] === item.id);

  return userType ? <FunctionField label="User Type" render={() => `${userType.name}`} /> : null;
};

UserTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "userType",
};

const FamilyStatusField = ({ source }: any) => {
  const record = useRecordContext();
  const familyStatus: any = familyStatuses.find((item: any) => record[source] === item.id);

  return familyStatus ? <FunctionField label="Family Status" render={() => `${familyStatus.name}`} /> : null;
};

FamilyStatusField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "familyStatus",
};

const OauthProviderField = ({ source }: any) => {
  const record = useRecordContext();
  const oauthProvider: any = oauthProviders.find((item: any) => record[source] === item.id);

  return oauthProvider ? <FunctionField label="OAuth - Provider" render={() => `${oauthProvider.name}`} /> : null;
};

OauthProviderField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "oauthProvider",
};

const NativeLanguageField = ({ source }: any) => {
  const record = useRecordContext();
  const nativeLanguage: any = nativeLanguages.find((item: any) => record[source] === item.id);

  return nativeLanguage ? <FunctionField label="Native Language" render={() => `${nativeLanguage.name}`} /> : null;
};

NativeLanguageField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "nativeLanguage",
};

const EmployeeStatusField = ({ source }: any) => {
  const record = useRecordContext();
  const employeeStatus: any = employeeStatuses.find((item: any) => record[source] === item.id);

  return employeeStatus ? <FunctionField label="Employee Status" render={() => `${employeeStatus.name}`} /> : null;
};

EmployeeStatusField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "employeeStatus",
};

const PrivacyField = ({ source }: any) => {
  const record = useRecordContext();
  const privacy: any = privacies.find((item: any) => record[source] === item.id);

  return privacy ? <FunctionField label="Privacy" render={() => `${privacy.name}`} /> : null;
};

PrivacyField.defaultProps = {
  addLabel: true,
  addField: true,
  source: "privacy",
};

export { RoleField, UserTypeField, OauthProviderField, FamilyStatusField, NativeLanguageField, EmployeeStatusField, PrivacyField };
