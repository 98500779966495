import * as React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceArrayField, SingleFieldList, ChipField, Datagrid, TextField, ArrayField } from "react-admin";

import { SurveyForm } from "../interfaces/surveyform";

const SurveyFormShow = (props: SurveyForm) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="title" addLabel />
    <FieldGuesser source="slug" addLabel />
    <ReferenceArrayField label="Survey" reference="survey_companies" source="surveyCompany">
      <SingleFieldList>
        <ChipField source="title" />
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="isActive" addLabel />
    <ReferenceArrayField label="Survey" reference="survey_form_steps" source="formSteps">
      <Datagrid>
        <ReferenceArrayField reference="survey_form_questions" source="formQuestions">
          <Datagrid>
            <TextField label="Question" source="label" />
            <TextField label="Type" source="type" />
            <ArrayField label="Answers" source="formAnswers">
              <Datagrid>
                <TextField source="value" />
                <TextField source="rating" />
              </Datagrid>
            </ArrayField>
            <TextField label="Question max score" source="averageRating" />
          </Datagrid>
        </ReferenceArrayField>
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export default SurveyFormShow;
